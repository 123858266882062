import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import Contact from "./components/ContactForm";
import Dashboard from "./components/Dashboard";
import SplashScreen from "./components/addons/SplashScreen";


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for a few seconds (you can replace this with actual loading logic)
    setTimeout(() => {
      setLoading(false);
    }, 2600); // Adjust the time as needed

    // Cleanup function
    return () => {
      // No need to set loading to false here
    };
  }, []);

  return (
    <div>
      <Router>
        {/* Conditionally render the SplashScreen while loading */}
        {loading ? (
          <SplashScreen />
        ) : (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}
export default App;
