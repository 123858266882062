import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setMobileMenuOpen(false); // Close the mobile menu after clicking a link
    }
  };

  return (
    <nav className="bg-[#e9ebf5] w-full fixed top-0 left-0 right-0 z-10">
      <div className="w-full mx-auto shadow-2xl" style={{ boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)" }}>
        <div className="flex items-center justify-between p-4">
          <Link
            to="/"
            className="text-[#021C41] text-lg font-bold flex items-center pb-2"
          >
            Nookadelic
          </Link>
          <div className="hidden md:flex space-x-4">
            <Link
              className="text-[#021C41] hover:text-gray-300 focus:outline-none"
              onClick={() => scrollToSection("about-section")}
            >
              About Us
            </Link>
            
            <Link
              className="text-[#021C41] hover:text-gray-300 focus:outline-none"
              onClick={() => scrollToSection("jasa-section")}
            >
              Layanan Jasa
            </Link>
            <Link
              className="text-[#021C41] hover:text-gray-300 focus:outline-none"
              onClick={() => scrollToSection("portfolio-section")}
            >
              Portfolio
            </Link>
            <Link
              className="text-[#021C41] hover:text-gray-300 focus:outline-none"
              onClick={() => scrollToSection("contact-section")}
            >
              Contact Us
            </Link>
          </div>
          <div className="md:hidden flex items-center">
            <button
              className="text-[#021C41] hover:text-gray-300 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              Menu
            </button>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <ul className="bg-[#e9ebf5] text-[#021C41] text-center">
            <li>
              <Link
                className="block py-2 hover:text-gray-300"
                onClick={() => scrollToSection("about-section")}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                className="block py-2 hover:text-gray-300"
                onClick={() => scrollToSection("jasa-section")}
              >
                Layanan Jasa
              </Link>
            </li>
            <li>
              <Link
                className="block py-2 hover:text-gray-300"
                onClick={() => scrollToSection("portfolio-section")}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                className="block py-2 hover:text-gray-300"
                onClick={() => scrollToSection("contact-section")}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
