import React from "react";
import adm from "../assets/card/Admirality Club House.jpg";
import bmo from "../assets/card/Bhuvana Marketing Office.jpg";
import csp from "../assets/card/Chevrolet Showroom Pluit.jpg";
import mgi from "../assets/card/Muji Grand Indonesia.jpg";
import oas from "../assets/card/Office ATTB Simatupang.jpg";
import gtk from "../assets/card/Garuda Tactical Kemang.jpg"

const Portfolio = () => {
  // Define the portfolio items array
  const ll = "https://drive.google.com/file/d/1r72qNEgXNxZUg145NmVtSG3CanRLH3oB/view" ;
  const portfolioItems = [
    {
      image: adm,
      title: "Admirality Club House",
      link: ll
    },
    {
      image: bmo,
      title: "Bhuvana Marketing Office",
      link: ll
    },
    {
      image: csp,
      title: "Chevrolet Showroom Pluit",
      link: ll
    },
    {
      image: mgi,
      title: "Muji Grand Indonesia",
      link: ll
    },
    {
      image: oas,
      title: "Office ATTB Simatupang",
      link: ll
    },
    {
      image: gtk,
      title: "Garuda Tactical Kemang",
      link: ll
    }
  ];

  return (
    <section className="container mx-auto mt-8">
      <h2 className="text-3xl font-semibold mb-4 text-[#021C41] text-center">
        Portofolio
      </h2>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 justify-items-center">
          {portfolioItems.map((item, index) => (
            <div
              key={index}
              className="w-64 h-80 bg-white p-4 transform hover:translate-y-2 hover:scale-105 transition-transform duration-300 rounded-lg shadow-md"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-40 object-cover mb-4 rounded-md"
              />
              <h3 className="text-gray-600 font-sans font-bold text-center mb-2">
                {item.title}
              </h3>
              <div className="text-center">
                <a
                  className="text-gray-600 hover:text-blue-500"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click To View More
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
